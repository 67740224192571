<script lang="ts">
    import { ComponentType } from 'svelte';

    import { DefaultOptions } from '$src/types/search.types';
    import SelectFilterDesktop from '$src/components/Filters/desktop/SelectFilterDesktop.svelte';

    import SelectFilterMobile from './mobile/SelectFilterMobile.svelte';
    import { filterOptions } from '$src/utils/searchFilters';
    import debounce from 'lodash/debounce';

    export let defaultOptions: DefaultOptions;
    export let inputLabel: string;
    export let inputName: string;
    export let menuTitle: string | undefined;
    export let icon: ComponentType;
    export let placeholder: string;
    export let placeholderMobile: string;
    export let analyticsQueryKey: string | null = null;

    let inputValue: string = '';

    let filteredOptions: DefaultOptions = defaultOptions;

    const handleFilterOptions = (query: string) => {
        filteredOptions = filterOptions({ query, defaultOptions });
    };

    const sendAnalyticsEvent = debounce((query: string) => {
        if (
            query.length > 0 &&
            window.dataLayer != null &&
            analyticsQueryKey != null
        ) {
            window.dataLayer.push({
                event: 'santianoEcommerce',
                eventCommand: 'search_input',
                [analyticsQueryKey]: query,
            });
        }
    }, 500);

    const onInputChange = (event: Event) => {
        const query = (event.target as HTMLInputElement).value;
        handleFilterOptions(query);
        sendAnalyticsEvent(query);
    };
    const resetInput = () => {
        inputValue = '';
        // reset filtered options
        handleFilterOptions('');
    };
</script>

<div class="hidden w-full desktop:block">
    <SelectFilterDesktop
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
    />
</div>

<div class="desktop:hidden">
    <SelectFilterMobile
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
        {placeholderMobile}
    />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name={inputName}
    value={inputValue}
/>
