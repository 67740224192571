<script>
    import { writable } from 'svelte/store';
    import Modal from '$src/components/Modal.svelte';
    import CrossIcon from '$src/icons/CrossIcon.svelte';

    export let text;
    export let title;

    let isModalOpen = writable(false);
</script>

<button
    class="text-xs font-semibold leading-6 text-white underline desktop:hidden"
    on:click={() => ($isModalOpen = true)}
>
    Lire la suite
</button>

<Modal bind:isOpen={isModalOpen}>
    <div slot="header" class="item-center flex justify-between">
        <button
            on:click={() => ($isModalOpen = false)}
            class="focus-visible:outline-none"
        >
            <CrossIcon class="size-xl fill-content-neutral" />
        </button>
    </div>

    <div class="mt-m px-xl">
        <h1 class="text-2xl font-semibold leading-7">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            {@html title}
        </h1>
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <div class="mt-m">{@html text}</div>
    </div>
</Modal>
