<script lang="ts">
    import { DefaultOptionList } from '$src/types/search.types';

    import { FilterFormValues } from '../types';
    import MultiSelectFilter from './Filters/MultiSelectFilter.svelte';
    import SliderFilter from './Filters/SliderFilter.svelte';

    export let defaultOptions: DefaultOptionList;
    export let formValues: FilterFormValues;

    export let handleSecondaryFiltersAutoSubmit: (filterName: string) => void;
</script>

<div class="relative mx-auto hidden h-[50px] max-w-layout-max desktop:block">
    <div class="absolute top-[-50%] w-full px-5xl">
        <div
            class="flex items-center justify-between border border-t-0 border-solid border-bord-quiet bg-white px-m"
        >
            <MultiSelectFilter
                inputLabel="Niveau sportif"
                options={defaultOptions.levels}
                bind:formValue={formValues.levels}
                onOutsideClick={() => handleSecondaryFiltersAutoSubmit('levels')}
            />
            <MultiSelectFilter
                inputLabel="Type de séjour"
                options={defaultOptions.types}
                bind:formValue={formValues.types}
                onOutsideClick={() => handleSecondaryFiltersAutoSubmit('types')}
            />
            <!-- Slider durée -->
            <SliderFilter
                title="Durée du séjour"
                bind:valueMin={formValues.durationMin}
                bind:valueMax={formValues.durationMax}
                label={'j'}
                defaultMin={defaultOptions.durationMin}
                defaultMax={defaultOptions.durationMax}
                onOutsideClick={() => handleSecondaryFiltersAutoSubmit('duration')}
            />
            <!-- Slider Budget -->
            <SliderFilter
                title="Budget par personne"
                bind:valueMin={formValues.budgetMin}
                bind:valueMax={formValues.budgetMax}
                label={'€'}
                defaultMin={defaultOptions.budgetMin}
                defaultMax={defaultOptions.budgetMax}
                onOutsideClick={() => handleSecondaryFiltersAutoSubmit('budget')}
            />
            <MultiSelectFilter
                inputLabel="Thème"
                options={defaultOptions.tags}
                bind:formValue={formValues.tags}
                onOutsideClick={() => handleSecondaryFiltersAutoSubmit('themes')}
            />
        </div>
    </div>
</div>
