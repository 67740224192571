<script lang="ts">
    import { DefaultOptions } from '$src/types/search.types';
    import Button from '$src/components/Button.svelte';
    import WalkIcon from '$src/icons/WalkIcon.svelte';
    import MapPinIcon from '$src/icons/MapPinIcon.svelte';
    import SelectFilter from '$src/views/HomePage/SearchFilters/SelectFilter/SelectFilter.svelte';
    import CalendarFilter from '$src/views/HomePage/SearchFilters/CalendarFilter/CalendarFilter.svelte';
    import PaxFilter from '$src/views/HomePage/SearchFilters/PaxFilter/PaxFilter.svelte';

    export let defaultDestinationOptions: DefaultOptions;
    export let defaultSportOptions: DefaultOptions;
    export let searchUrl: string;

    const handleSubmit = (event: Event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);

        // Récupérer les valeurs des champs cachés
        let destinationLabel = 'without_search';
        let sportLabel = 'without_search';
        let departureDate = 'without_search';
        let travelers = 'without_search';

        if (formData.get('destination[]')) {
            destinationLabel =
                defaultDestinationOptions.find(
                    (option) => option.value === formData.get('destination[]'),
                )?.label || 'without_search';
        }
        if (formData.get('theme[]')) {
            sportLabel =
                defaultSportOptions.find(
                    (option) => option.value === formData.get('theme[]'),
                )?.label || 'without_search';
        }
        if (formData.get('startDate')) {
            departureDate =
                (formData.get('startDate') as string) || 'without_search';
        }
        if (formData.get('nbPax')) {
            travelers = (formData.get('nbPax') as string) || 'without_search';
        }

        // Pousser les données dans le dataLayer
        if (window.dataLayer != null) {
            window.dataLayer.push({
                event: 'santianoEcommerce',
                eventCommand: 'search',
                destination: destinationLabel,
                sport: sportLabel,
                departureDate: departureDate,
                travelers: travelers,
            });
        }

        // Soumettre le formulaire
        const form = event.target as HTMLFormElement;
        form.submit();
    };
</script>

<!-- calc width with padding because absolute positioning -->
<div
    class="relative mx-2xl h-[172px] desktop:m-auto desktop:h-[100px] desktop:max-w-layout-max"
>
    <div class="absolute top-[-140%] desktop:top-[-180%]">
        <div class="desktop:max-w-layout-max desktop:px-5xl">
            <h1
                class="text-left text-[26px] font-semibold leading-[120%] text-white desktop:text-5xl"
            >
                Réservez votre <br /> prochain séjour sportif
            </h1>
        </div>
    </div>
    <div class="absolute top-[-90%] w-full desktop:top-[-50%]">
        <div class="desktop:max-w-layout-max desktop:px-5xl">
            <div class="border border-solid border-bord-quiet bg-white p-m">
                <form
                    autocomplete="off"
                    method="GET"
                    action={searchUrl}
                    on:submit={handleSubmit}
                >
                    <div class="flex flex-col gap-m desktop:flex-row">
                        <SelectFilter
                            inputName="destination[]"
                            inputLabel="Destination"
                            menuTitle="Sélectionner une destination"
                            icon={MapPinIcon}
                            placeholder="Ex: France"
                            placeholderMobile="Rechercher une destination"
                            defaultOptions={defaultDestinationOptions}
                            analyticsQueryKey="destination"
                        />
                        <SelectFilter
                            inputName="theme[]"
                            inputLabel="Sport"
                            menuTitle="Sélectionner un sport"
                            icon={WalkIcon}
                            placeholder="Ex: randonnée"
                            placeholderMobile="Rechercher un sport"
                            defaultOptions={defaultSportOptions}
                            analyticsQueryKey="sport"
                        />
                        <CalendarFilter />
                        <PaxFilter />
                        <Button class="w-full desktop:hidden" type="submit"
                            >Rechercher</Button
                        >
                        <Button
                            size="large"
                            class="hidden w-fit desktop:block"
                            type="submit">Rechercher</Button
                        >
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
