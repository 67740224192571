<script lang="ts">
    import { writable } from 'svelte/store';
    import dayjs from 'dayjs';
    import { parseDate } from '@internationalized/date';

    import cn from '$src/utils/cn';
    import FilterIcon from '$src/icons/FilterIcon.svelte';
    import viewport from '$src/actions/useViewport';
    import { DefaultOptionList } from '$src/types/search.types';
    import FilterBadges from '$src/views/List/Search/desktop/FilterBadges.svelte';
    import MainFilters from './desktop/MainFilters.svelte';
    import FiltersModal from './mobile/FiltersModal.svelte';
    import SecondaryFilters from './desktop/SecondaryFilters.svelte';
    import { FilterFormValues, SearchForm } from './types';
    import { findCurrentSelectedFilterLabel } from '$src/views/List/Search/utils';

    export let defaultOptions: DefaultOptionList;
    export let searchForm: SearchForm;

    let isSticky = false;
    let isModalOpen = writable(false);

    let formValues: FilterFormValues = {
        destination: searchForm.destinations?.[0]?.toString() ?? '',
        theme: searchForm.sports?.[0]?.toString() ?? '',
        startDate: searchForm?.startDate?.date
            ? parseDate(dayjs(searchForm.startDate.date).format('YYYY-MM-DD'))
            : undefined,
        flexibility: searchForm.flexibility ?? 0,
        adults: searchForm.nbPax?.toString() ?? '0',
        children: '0',
        childrenAges: [],
        // secondary
        levels: searchForm.levels ?? [],
        types: searchForm.types ?? [],
        durationMin: searchForm.durationMin ?? defaultOptions.durationMin,
        durationMax: searchForm.durationMax ?? defaultOptions.durationMax,
        budgetMin: searchForm.budgetMin ?? defaultOptions.budgetMin,
        budgetMax: searchForm.budgetMax ?? defaultOptions.budgetMax,
        tags: searchForm.tags ?? [],
    };

    const handleFormSubmissionAnalytics = (filterName: string | null = null) => {
        // Récupérer les valeurs des champs cachés
        let destinationLabel = 'without_search';
        let levelLabel =
            formValues.levels.map((level) =>
                findCurrentSelectedFilterLabel(level, defaultOptions.levels),
            ) ?? 'without_search';
        let sportLabel = 'without_search';
        let monthLabel = 'without_search';
        let tagLabel =
            formValues.tags.map((tag) =>
                findCurrentSelectedFilterLabel(tag, defaultOptions.tags),
            ) ?? 'without_search';
        let typeLabel =
            formValues.types.map((type) =>
                findCurrentSelectedFilterLabel(type, defaultOptions.types),
            ) ?? 'without_search';
        let departureDate = 'without_search';
        let travelers = 'without_search';

        // Pousser les données dans le dataLayer
        if (filterName) {
            let filterValue = null;
            if (filterName === 'themes') {
                filterValue = tagLabel;
            }
            if (filterName === 'levels') {
                filterValue = levelLabel;
            }
            if (filterName === 'types') {
                filterValue = typeLabel;
            }
            if (filterName === 'month') {
                filterValue = monthLabel;
            }
            if (filterName === 'duration') {
                filterValue = `${formValues.durationMin} - ${formValues.durationMax} jours`;
            }
            if (filterName === 'budget') {
                filterValue = `${formValues.budgetMin} - ${formValues.budgetMax} €`;
            }
            if (window.dataLayer != null) {
                window.dataLayer.push({
                    event: 'santianoEcommerce',
                    eventCommand: 'filter',
                    filterName: filterName,
                    filterValue: filterValue,
                });
            }
        } else {
            if (window.dataLayer != null) {
                window.dataLayer.push({
                    event: 'santianoEcommerce',
                    eventCommand: 'search',
                    destination: destinationLabel,
                    sport: sportLabel,
                    departureDate: departureDate,
                    travelers: travelers,
                });
            }
        }
    };

    /**
     * When a filter change value, auto submit form
     * (after user close popover)
     */
    const handleSecondaryFiltersAutoSubmit = (filterName: string | null = null) => {
        const form = document.getElementById(
            'travel-search-engine-form',
        ) as HTMLFormElement;
        handleFormSubmissionAnalytics(filterName);
        if (form) form.submit();
    };
</script>

<!-- main form filters, hidden -->
<!--Desti -->
<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="destination[]"
    value={formValues.destination ?? ''}
/>

<!--Sport -->
<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="theme[]"
    value={formValues.theme ?? ''}
/>

<!--    startDate -->
<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="startDate"
    value={formValues.startDate
        ? dayjs(formValues.startDate.toString()).format('DD/MM/YYYY')
        : ''}
/>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="flexibility"
    value={formValues.flexibility}
/>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="nbPax"
    value={Number(formValues.adults) + Number(formValues.children)}
/>

<!-- Secondary filters inputs -->
{#each formValues.levels as level}
    <input
        aria-hidden="true"
        tabindex="-1"
        class="hidden"
        name="level[]"
        value={level}
    />
{/each}

{#each formValues.types as type}
    <input
        aria-hidden="true"
        tabindex="-1"
        class="hidden"
        name="type[]"
        value={type}
    />
{/each}

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="durationMin"
    value={formValues.durationMin}
/>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="durationMax"
    value={formValues.durationMax}
/>
<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="budgetMin"
    value={formValues.budgetMin}
/>
<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="budgetMax"
    value={formValues.budgetMax}
/>

{#each formValues.tags as tag}
    <input
        aria-hidden="true"
        tabindex="-1"
        class="hidden"
        name="tag[]"
        value={tag}
    />
{/each}

<!-- Mobile -->
<div
    use:viewport
    on:enterViewport={() => {
        isSticky = false;
    }}
    on:exitViewport={() => {
        isSticky = true;
    }}
/>
{#if isSticky}
    <div class={'h-[54px]'} />
{/if}
<div
    class={cn(
        'transition-all',
        isSticky && 'fixed top-0 z-10 w-full px-0 py-0',
        !isSticky && 'px-xl py-m',
    )}
>
    <button
        type="button"
        class={cn(
            'flex w-full items-center justify-center gap-2 bg-white px-xl py-m font-semibold leading-6 text-content-neutral desktop:hidden',
            isSticky && 'rounded-none',
        )}
        on:click={() => ($isModalOpen = true)}
    >
        Filtrer les séjours
        <FilterIcon class="size-l fill-content-neutral" />
    </button>
</div>

<!-- Mobile -->
<FiltersModal {defaultOptions} bind:formValues bind:isModalOpen />
<!-- -->

<!-- Desktop -->
<MainFilters {defaultOptions} bind:formValues {handleFormSubmissionAnalytics} />
<SecondaryFilters
    {defaultOptions}
    bind:formValues
    {handleSecondaryFiltersAutoSubmit}
/>
<FilterBadges {defaultOptions} {searchForm} bind:formValues />
<!-- -->
