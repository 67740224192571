<script lang="ts">
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import { parseDate } from '@internationalized/date';
    import { fade } from 'svelte/transition';

    import cn from '$src/utils/cn';
    import type { DateRange } from '$src/utils/date';
    import { findFirstAvailableMonth } from '$src/utils/date';
    import CalendarPicker from '$src/components/CalendarPicker.svelte';
    import { dateValue } from '$src/store/tourFitDate';

    export let closedSales;
    export let disabled;
    let closedSalesFormatted: DateRange[];
    $: {
        const parsedClosedSales = JSON.parse(closedSales);
        closedSalesFormatted = parsedClosedSales.map(
            (closedSale: { startDate: string; endDate: string }) => {
                return {
                    startDate: dayjs(closedSale.startDate),
                    endDate: dayjs(closedSale.endDate),
                };
            },
        );
    }
    let calendarTriggerBtn: HTMLButtonElement;

    const {
        elements: {
            trigger,
            calendar,
            cell,
            grid,
            heading,
            nextButton,
            prevButton,
            content,
            segment,
            hiddenInput,
        },
        states: {
            value,
            months,
            headingValue,
            segmentContents,
            open,
            weekdays,
        },
        helpers: { isDateDisabled, isDateUnavailable, setMonth, setYear },
    } = createDatePicker({
        locale: 'fr',
        name: 'startDate',
        numberOfMonths: 2,
        forceVisible: true,
        positioning: {
            placement: 'bottom-end',
        },
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        isDateDisabled: (date) => {
            let isDisabled = false;
            for (const closedDate of closedSalesFormatted) {
                const d = dayjs(date.toString());
                // '[]' is inclusive start+end day dates
                if (
                    d.isBetween(
                        closedDate.startDate,
                        closedDate.endDate,
                        'day',
                        '[]',
                    )
                )
                    isDisabled = true;
            }

            return isDisabled;
        },
        onValueChange: (dateValue) => {
            // close calendar on choosing date value
            calendarTriggerBtn?.click();

            return dateValue.next;
        },
        value: dateValue,
    });

    $: {
        if ($open) {
            // find the closest date available, if not in current month then set
            // to the closest date's month
            // dayjs starts at 0, melt-ui at 1
            // + one more offset to display the 1st available month on the left
            const firstAvailableDate =
                findFirstAvailableMonth(closedSalesFormatted);
            setYear(firstAvailableDate.year());
            setMonth(firstAvailableDate.month() + 1);
        }
    }
</script>

<div>
    <button
        bind:this={calendarTriggerBtn}
        type="button"
        {...$trigger}
        use:trigger
        class={cn(
            'flex h-4xl w-full items-center border border-solid border-border-neutral bg-white pl-m pr-2xl',
            disabled && 'hidden',
        )}
    >
        {#if $value == null}
            <div class="text-sm text-content-neutral">Choisir une date</div>
        {:else}
            {#each $segmentContents as seg}
                <div
                    {...$segment(seg.part)}
                    use:segment
                    class="text-sm text-content-neutral"
                >
                    {seg.value}
                </div>
            {/each}
        {/if}
    </button>
    <input
        {...$hiddenInput}
        use:hiddenInput
        hidden={false}
        id="next-dates"
        value={$value != null ? dayjs($value).format('YYYY-MM-DD') : ''}
    />

    {#if $open}
        <div transition:fade={{ duration: 100 }} {...$content} use:content>
            <CalendarPicker
                class="px-2xl py-m"
                {calendar}
                {prevButton}
                {nextButton}
                {heading}
                {headingValue}
                {months}
                {grid}
                {weekdays}
                {isDateDisabled}
                {isDateUnavailable}
                {cell}
                isMultipleMonths={true}
            />
        </div>
    {/if}
</div>

<style lang="postcss">
    [data-melt-popover-content] {
        @apply z-50 border border-solid border-border-quiet bg-white;
    }
</style>
