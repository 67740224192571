<!-- TODO merge with MultiSelectFilter as one Select component with "multi" option -->
<script lang="ts">
    import { ComponentType } from 'svelte';
    import debounce from 'lodash/debounce';

    import SelectFilterDesktop from '$src/components/Filters/desktop/SelectFilterDesktop.svelte';
    import { DefaultOptions } from '$src/types/search.types';
    import { filterOptions } from '$src/utils/searchFilters';

    export let defaultOptions: DefaultOptions;
    export let inputValue: string;

    export let icon: ComponentType;
    export let placeholder: string;
    export let inputLabel: string;
    export let menuTitle: string | undefined;
    export let analyticsQueryKey: string | null = null;

    let filteredOptions = defaultOptions;

    const handleFilterOptions = (query: string) => {
        filteredOptions = filterOptions({ query, defaultOptions });
    };

    const sendAnalyticsEvent = debounce((query: string) => {
        if (
            query.length > 0 &&
            window.dataLayer != null &&
            analyticsQueryKey != null
        ) {
            window.dataLayer.push({
                event: 'santianoEcommerce',
                eventCommand: 'search_input',
                [analyticsQueryKey]: query,
            });
        }
    }, 500);

    const onInputChange = (event: Event) => {
        const query = (event.target as HTMLInputElement).value;
        handleFilterOptions(query);
        sendAnalyticsEvent(query);
    };

    const resetInput = () => {
        // reset filtered options
        handleFilterOptions('');
    };
</script>

<SelectFilterDesktop
    bind:inputValue
    {onInputChange}
    {resetInput}
    {filteredOptions}
    {defaultOptions}
    {inputLabel}
    {menuTitle}
    {icon}
    {placeholder}
/>
